import {
  TPersonalParameters,
  TSearchParameters,
} from '@/contexts/AreaContext/AreaContext';

export type TPartList = {
  id: number;
  bom: string;
  part_type: string;
  frame_family: string;
  part_owners: string[];
  application: string;
  pdp_status: string;
  sap_status: string;
  engineering_status: string;
  base_material: string;
  internal_coating: string;
  external_coating: string;
  thermal_barrier: string;
  top_coat: string;
  sub_styles: string;
  assembly_cluster: string;
  life_cluster: string;
  source: string;
  created_at: string;
  action_type: string;
};
export type TPartListOrdering = 'ASC' | 'DESC';

export type TPartWithId = {
  id: number;
  value: string;
};
export type TPartWithIdOrdinal = {
  id: number;
  value: string;
  ordinal: string;
};

export type TPartEarmarks = {
  id: number;
  earmark_id: number;
  earmark_value: string;
};
export type TPartPdpStatus = {
  id: number;
  pdp_status_id: number;
  pdp_status_value: string;
};

export type TPartEngineeringStatus = {
  id: number;
  engineering_status_id: number;
  engineering_status_value: string;
};

export type TPart = {
  id: number;
  title: TPartTitle;
  description: TPartWithId;
  earmarks: TPartEarmarks[];
  part_owners: TPartOwner[];
  status: TPartStatus;
  scrap_rates: TPartScrapRates[];
  set_part_count: TPartSetCount[];
  material: TPartMaterial;
  casting_vendors: string[];
  design_features: TPartDesignFeature[];
  applications: string[];
  part_lifes: TPartLife[];
  maintenance_concepts: TPartWithId[];
  predecessors: TPartWithId[];
  successors: TPartWithId[];
  alternative_sources: TPartAlternativeSource[];
  design_reviews: TPartDesignReview[];
  references: TPartReference[];
  application_matrix: TPartApplicationMatrix[];
  application_matrix_incoming: Partial<TPartApplicationMatrix>[];
};

export type TPartDesignFeature = {
  id: number;
  design_feature_id: number;
  ordinal: string;
  design_feature: string;
};
export type TPartTitle = {
  bom: string;
  part_type: string;
  frame_family: string;
};
export type TPartOwner = {
  ownership: string;
  owners: string[];
};
export type TPartStatus = {
  pdp_status: TPartPdpStatus;
  sap_status: string;
  engineering_status: TPartEngineeringStatus;
};
export type TPartSetCount = {
  description: string;
  material_master: string;
  count: string;
  path: string;
};
export type TPartScrapRates = {
  bom: string;
  ambient_temp: string;
  concept: string;
  tit_iso?: number;
  mrl_hrs?: number;
  mrl_strts?: number;
  mrl_rpr?: number;
  applications: string;
};
export type TPartMaterial = {
  base_material: string;
  internal_coating: string;
  external_coating: string;
  thermal_barrier: string;
  top_coat: string;
};
export type TPartLife = {
  id: number;
  frame: string;
  tit_iso: string;
  total_starts: string;
  creep: string;
  hcf: string;
  int_oxidation: string;
  ext_oxidation: string;
  status: string;
};
export type TPartAlternativeSource = {
  alternative_source: string;
  source_type: string;
};
export type TPartDesignReview = {
  design_review: string;
  url: string;
};
export type TPartReference = {
  id: number;
  reference: string;
  url: string;
};

export const tagCategories = [
  'airfoil',
  'cooling',
  'damper',
  'Development Program',
  'Issue mitigation',
  'leading edge',
  'root',
  'sealing',
  'Testing',
  'throttle plate',
  'tip',
  'trailing edge',
  'Usage',
  'whole part',
] as const;

export type TPartTagArray = {
  tags: TPartTag[];
};

export type TPartTag = {
  id: number;
  tag: string;
  category: (typeof tagCategories)[number] | '';
  prev_tag_id: number | null;
  count: number;
};

export type TPartApplicationMatrix = {
  main_cluster: string;
  incoming_set_style: string;
  incoming_sub_style: string;
  characteristic_in: string;
  life_cluster_in: string;
  life_cluster_in_color: string;
  assembly_cluster_in: string;
  assembly_cluster_in_color: string;
  outcoming_set_style: string;
  outcoming_sub_style: string;
  characteristic_out: string;
  life_cluster_out: string;
  life_cluster_out_color: string;
  assembly_cluster_out: string;
  assembly_cluster_out_color: string;
  status: string;
};

export type TPartSearch = {
  parts: TPartList[];
};

export type TPersonalFilters = {
  personal_filters: {
    id: number;
    filters: TSearchParameters;
    is_enabled: boolean;
  };
};
export type TPersonalFiltersArray = {
  personal_filters: {
    id: number;
    filters: TPersonalParameters;
    is_enabled: boolean;
  };
};
